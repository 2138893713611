<template>
  <Dialog v-model:visible="show"
          :header="t('label.chat')"
          position="bottomright"
          :draggable="true"
          :maximizable="false"
          :modal="false"
          style="width: 40vw; height: 64.8vh; z-index: 999999999">

    <div class="grid grid-nogutter">
      <div class="col-4 md:col-4">
        <Listbox v-model="selectedUser"
                 :options="users"
                 :multiple="false"
                 :filter="true"
                 option-label="username"
                 :filterPlaceholder="t('action.search')"
                 listStyle="max-height:50vh"
                 @change="onUserSelect">
          <template #option="slotProps">
            <div class="flex align-items-end">
              <span style="width: 10vw">{{ slotProps.option.username }}</span>
              <span v-if="slotProps.option.unreadMessages > 0">
                  <Badge :value="slotProps.option.unreadMessages" severity="danger"></Badge>
                </span>
            </div>
          </template>
        </Listbox>
      </div>

      <div class="col-8 md:col-8">
        <div class="chat">

          <div class="p-fluid">
            <div class="field">
              <ScrollPanel class="p-mb-1" style="width: 100%; height: 50vh">
                <ChatContent v-model:messages="messages"/>
              </ScrollPanel>
            </div>

            <!-- send area -->
            <div class="field">
              <div class="chat-input">
                <div class="grid grid-nogutter ml-2">
                  <div class="col-11">
                    <InputText type="text" v-model="message"
                               :placeholder="t('label.chat.write-hint')"
                               v-on:keyup.enter="sendMessage"
                               style="width: 100%"/>
                  </div>
                  <div class="col-1">
                    <Button icon="pi pi-send" @click="sendMessage"/>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import ChatContent from "@/components/chat/ChatContent";
import {useI18n} from "vue-i18n";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import {useStore} from "vuex";
import useMessage from "@/composable/useMessage";
import EventService from "@/services/EventService";
import {SHOW_CHAT} from "@/components/common/constants/Events";
import {isEmpty, isNil} from "lodash/lang";

const {t} = useI18n();
const store = useStore();
const {info} = useMessage();

let stompClient;
const show = ref(false);
const username = computed(() => store.getters['auth/username']);
const message = ref();
const users = computed(() => store.getters["chat/users"])
const messages = computed(() => store.getters["chat/messaggi"](selectedUser.value.username))
const selectedUser = computed({
  get: () => store.getters['chat/defaultUser'],
  set: (usr) => store.dispatch("chat/setDefaultUser", usr)
});

onMounted(async () => {
  connect();
  await store.dispatch("chat/loadUsers");
  EventService.on(SHOW_CHAT, showChatModal);
})


const connect = () => {
  const socket = new SockJS("/api/chat");
  stompClient = Stomp.over(socket);
  stompClient.debug = () => {
  };
  stompClient.connect({username: username.value}, onConnected, onError);
}

const sendMessage = () => {
  if (!isNil(message.value) && !isEmpty(message.value) && stompClient) {
    const newMessage = {
      content: message.value,
      sender: username.value,
      recipient: selectedUser.value.username,
      datetime: new Date()
    };

    stompClient.send("/app/chat", JSON.stringify(newMessage), {});
    message.value = "";
  }

}

const onConnected = () => {
  stompClient.subscribe('/user/queue/messages', onMessageReceived);
  stompClient.subscribe('/user/queue/events', onEventReceived);
}


const onMessageReceived = (payload) => {
  const currentMessage = JSON.parse(payload.body);
  currentMessage.currentUser = username.value === currentMessage.sender;
  store.dispatch('chat/addMessaggio', {username: username.value, messaggio: currentMessage});
}

const onEventReceived = (payload) => {
  const notifica = JSON.parse(payload.body);
  store.dispatch("notifiche/add", notifica);
  info(notifica.message, t('label.workflow-new-event'), false);
}

const onError = (e) => {
  console.log("onError ", e);
}

const onUserSelect = (e) => {
  if (isNil(e.value)) {
    store.dispatch("chat/setDefaultUser", users.value[0]);
  }
  store.dispatch("chat/resetUnreadMessages", e.value);
}

const showChatModal = () => {
  show.value = !show.value;
}


</script>

<style scoped>

.chat {
  background-color: #fdfdfd;
}

</style>
