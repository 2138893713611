import {createApp, h} from 'vue';
import {reactive} from 'vue';
import router from './router/index';
import AppWrapper from './AppWrapper.vue';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
//import Carousel from 'primevue/carousel';
//import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
//import Chip from 'primevue/chip';
//import Chips from 'primevue/chips';
//import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import FullCalendar from 'primevue/fullcalendar';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
//import Knob from 'primevue/knob';
//import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SplitButton from 'primevue/splitbutton';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
//import Rating from 'primevue/rating';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
import permission from "@/directive/permission";
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
//import Splitter from 'primevue/splitter';
//import SplitterPanel from 'primevue/splitterpanel';
//import TriStateCheckbox from 'primevue/tristatecheckbox';
//import CodeHighlight from './AppCodeHighlight';

import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
// import '@fullcalendar/core/main.min.css';
// import '@fullcalendar/daygrid/main.min.css';
// import '@fullcalendar/timegrid/main.min.css';
import './App.scss';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import {i18n, primeVueMessages} from '@/i18n/i18n';
import store from './store';

import '@/assets/theme/blue/theme-light.css';
import '@/assets/layout/css/layout-light.css';
import EventService from "@/services/EventService";
import httpClient from "@/services/HttpService";

// import logAPI from "@/services/LogRestService";
import VueLogger from 'vuejs3-logger';
import {isEmpty} from "lodash/lang";

const app = createApp({
    render() {
        return h(AppWrapper)
    }
});

// appState both as global var than with provide
app.config.globalProperties.$appState = reactive({
    inputStyle: 'filled',
    colorScheme: 'light',
    isNewThemeLoaded: false
});
//provide('appState', app.config.globalProperties.$appState);


app.use(store);

app.use(router);
app.use(i18n);
app.use(ToastService);
app.use(ConfirmationService);

const isProduction = process.env.NODE_ENV === 'production';
const loggerOptions = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};
app.use(VueLogger, loggerOptions);

// TODO: temporanea, evita .value per le variabili injected, default in prossime versioni vue
app.config.unwrapInjectedRef = true;


// global error handler
app.config.errorHandler = (error, vm, info) => {
    console.log("GLOBAL ERROR: ", error, vm, info);
    //logAPI.log(error + "-" + info);
}


// primevue components
app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
//app.directive('code', CodeHighlight);
app.directive('badge', BadgeDirective);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
//app.component('Carousel', Carousel);
//app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
//app.component('Chip', Chip);
//app.component('Chips', Chips);
//app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Editor', Editor);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('FullCalendar', FullCalendar);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
//app.component('Galleria', Galleria);
//app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
//app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SplitButton', SplitButton);
//app.component('Splitter', Splitter);
//app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeTable', TreeTable);
//app.component('TriStateCheckbox', TriStateCheckbox);

// vue-loading
app.component('Loading', Loading);

// custom components
app.directive('permission', permission);

app.use(VueKeyCloak, {
    config: {
        url: process.env.VUE_APP_KEYCLOAK_URL,
        realm: process.env.VUE_APP_KEYCLOAK_REALM,
        clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID
    }, onReady: async (kc) => {
        // globalProperties
        // or getCurrentInstance().appContext.config.globalProperties.$keycloak in setup method
        // or use app.provide e app.inject (not only for components)
        app.config.globalProperties.$keycloak = kc;


        // define interceptors (axios)
        httpClient.interceptors.request.use(config => {
            EventService.emit('progress', true);
            config.headers.Authorization = 'Bearer ' + kc.token;
            return config;
        });
        httpClient.interceptors.response.use(response => {
            EventService.emit('progress', false);
            return response;
        }, async error => {
            EventService.emit('progress', false);

            if (401 === error.response.status) {
                console.log("Response error ", error.response.status);
                kc.logout();
            } else {
                console.log("Error ", error.response.data);
                let errorMessage = !isEmpty(error.response.data.errorMessage) ? error.response.data.errorMessage : error.response.statusText;
                EventService.emit('toast', {
                    severity: 'error',
                    summary: i18n.global.t('label.error'),
                    life: 10000,
                    detail: errorMessage
                });
            }

            return Promise.reject(error);
        });


        // init
        await store.dispatch("auth/fetchUser");
        i18n.global.locale = await store.getters['auth/locale'];
        app.use(PrimeVue, {ripple: true, locale: primeVueMessages()});


        // mount and start app
        await app.mount('#app');

        // redirect to route
        //await router.push('/');
    }
});
