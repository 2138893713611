import restService from "@/services/ConfigRestService";
import {i18n} from "@/i18n/i18n";


export default {
    namespaced: true,
    state() {
        return {
            languages: [],
            datatable: {
                rows: 10,
                rowsPerPageOptions: [10, 25, 50],
                paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
                currentPageReportTemplate: i18n.global.t('label.paginator',{ first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
            },
            upload: {
                fileLimit: 100,
                maxFileSizeBytes: 1000000000
            }
        }
    },
    getters: {
        languages: (state) => state.languages,
        datatable: (state) => state.datatable,
        upload: (state) => state.upload,
    },
    actions: {
        loadLanguages: async ({commit}) => {
            const languages = (await restService.languages()).data;
            commit("saveLanguages", languages);
        },
    },
    mutations: {
        saveLanguages(state, languages) {
            state.languages = languages;
        }
    }
}
