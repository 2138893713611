// soggetto
const SHOW_SOGGETTO_DATA_DIALOG = "showSoggettoDialog";
const SHOW_SOGGETTO_DELETE_DIALOG = "showSoggettoDeleteDialog";
const SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG = "showSoggettoRichiestaDataDialog";
const SHOW_SOGGETTO_RICHIESTA_CONFIRM_DIALOG = "showSoggettoRichiestaConfirmDialog";

// utente
const SHOW_UTENTE_DATA_DIALOG = "showUtenteDialog";

// executionManager
const SHOW_SELECT_EXECUTION_MANAGER_DIALOG = 'showSelectExManagerDialog';
const SHOW_INSERT_EXECUTION_MANAGER_DIALOG = 'showInsertExecutionManagerDialog';

// aziende
const SHOW_SELECT_AZIENDE_DIALOG = "showSelAzDialog";

// spedizioniere
// const SHOW_SELECT_SPEDIZIONIERE_FERROVIARIO_DIALOG = 'showSelectSpedFerrDialog';
const SHOW_INSERT_SPEDIZIONIERE_FERROVIARIO_DIALOG = 'showInsertSpedFerrDialog';

// surveyor
const SHOW_SELECT_SURVEYOR_DIALOG = "showSurveyorDialog";

// custom broker
const SHOW_SELECT_CUSTOM_BROKER_DIALOG = "showCustomBrokerDialog";

// carro
const SHOW_DATA_CARRO_DIALOG = "showDataCarroDialog";
const SHOW_DELETE_CARRO_DIALOG = "showDeleteCarroDialog";
const SHOW_UPLOAD_FILES_CARRO_DIALOG = "showUploadFilesCarroDialog";
const SHOW_CARRO_CHANGE_STATUS_DIALOG = "showCarroChangeStatusDialog";
const SHOW_CARRO_ANALISI_DIALOG = "showCarroAnalisiDialog";
const SHOW_SCADENZE_CARRO_DIALOG = "showScadenzeCarroDialog";
const SHOW_MAP_CARRO_DIALOG = "showMapCarroDalog";

// contratto
const SHOW_CONTRATTO_DATA_DIALOG = "showContrattoDialog";
const SHOW_CONTRATTO_DELETE_DIALOG = "showContrattoDeleteDialog";
const SHOW_CONTRATTO_UPLOAD_FILES_DIALOG = "showContrattoUploadFilesDialog";

// nodo
const SHOW_NODO_DATA_DIALOG = "showDataNodoDialog";
const SHOW_NODO_DELETE_DIALOG = "showDeleteNodoDialog";

// tipo carro
const SHOW_DATA_TIPO_CARRO_DIALOG = "showDataTipoCarroDialog";
const SHOW_DELETE_TIPO_CARRO_DIALOG = "showDeleteTipoCarroDialog";

// muta
const SHOW_MUTA_DATA_DIALOG = "showMutaDataDialog";
const SHOW_MUTA_COMPOSIZIONE_DIALOG = "showMutaComposizioneDialog";

// viaggi
const SHOW_VIAGGIO_DATA_DIALOG = "showViaggioDataDialog";
const SHOW_VIAGGIO_INTERATTIVO_DATA_DIALOG = "showViaggioInterattivoDataDialog";
const SHOW_PIANIFICAZIONE_DATA_DIALOG = "showPianificazioneDataDialog";
const SHOW_VIAGGIO_VUOTO_DATA_DIALOG = "showViaggioVuotoDataDialog";
const SHOW_VIAGGIO_PIENO_DATA_DIALOG = "showViaggioPienoDataDialog";
const SHOW_VIAGGIO_UPLOAD_FILE_DIALOG = "showViaggioUploadFileDialog";
const SHOW_GANTT_DIALOG = "showGanttDialog";
const SHOW_VIAGGI_WATCHING_TAB = "showViaggiWatchingTab";

// pianificazione
const SHOW_PIANIFICAZIONE_REALE_DIALOG = "showPianigicazioneRealeDialog";

// state timeline
const SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG = "showWorkflowTimelineDiagramDialog";

// notifiche workflow
const WORKFLOW_NOTIFICATION = "workflowNotification";

// tipo documento
const SHOW_TIPO_DOCUMENTO_DATA_DiALOG = "showTipoDocumentoDataDialog";

// chat
const SHOW_CHAT = "showChat";

// mail
const SHOW_MAIL = "showMail"

// actions
const INSERT_ACTION = "insert";
const UPDATE_ACTION = "update";
const VIEW_ACTION = "view";
const NONE_ACTION = "none";

// toast message
const TOAST = "toast";

export {
    NONE_ACTION,
    INSERT_ACTION,
    UPDATE_ACTION,
    VIEW_ACTION,
    SHOW_SELECT_EXECUTION_MANAGER_DIALOG,
    SHOW_INSERT_EXECUTION_MANAGER_DIALOG,
    SHOW_INSERT_SPEDIZIONIERE_FERROVIARIO_DIALOG,
    SHOW_SELECT_SURVEYOR_DIALOG,
    SHOW_DATA_CARRO_DIALOG,
    SHOW_DELETE_CARRO_DIALOG,
    SHOW_UPLOAD_FILES_CARRO_DIALOG,
    SHOW_DATA_TIPO_CARRO_DIALOG,
    SHOW_DELETE_TIPO_CARRO_DIALOG,
    SHOW_MUTA_DATA_DIALOG,
    SHOW_MUTA_COMPOSIZIONE_DIALOG,
    SHOW_CARRO_CHANGE_STATUS_DIALOG,
    SHOW_SOGGETTO_DATA_DIALOG,
    SHOW_SOGGETTO_DELETE_DIALOG,
    SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG,
    SHOW_SOGGETTO_RICHIESTA_CONFIRM_DIALOG,
    SHOW_UTENTE_DATA_DIALOG,
    SHOW_CARRO_ANALISI_DIALOG,
    SHOW_SCADENZE_CARRO_DIALOG,
    SHOW_MAP_CARRO_DIALOG,
    SHOW_VIAGGIO_DATA_DIALOG,
    SHOW_VIAGGIO_INTERATTIVO_DATA_DIALOG,
    SHOW_VIAGGIO_PIENO_DATA_DIALOG,
    SHOW_VIAGGIO_VUOTO_DATA_DIALOG,
    SHOW_VIAGGIO_UPLOAD_FILE_DIALOG,
    SHOW_VIAGGI_WATCHING_TAB,
    SHOW_PIANIFICAZIONE_DATA_DIALOG,
    SHOW_NODO_DATA_DIALOG,
    SHOW_NODO_DELETE_DIALOG,
    SHOW_PIANIFICAZIONE_REALE_DIALOG,
    SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG,
    WORKFLOW_NOTIFICATION,
    SHOW_TIPO_DOCUMENTO_DATA_DiALOG,
    SHOW_CHAT,
    SHOW_MAIL,
    SHOW_CONTRATTO_DATA_DIALOG,
    SHOW_CONTRATTO_DELETE_DIALOG,
    SHOW_CONTRATTO_UPLOAD_FILES_DIALOG,
    SHOW_GANTT_DIALOG,
    TOAST,
    SHOW_SELECT_CUSTOM_BROKER_DIALOG,
    SHOW_SELECT_AZIENDE_DIALOG
}
