import {createI18n} from "vue-i18n";


export const SUPPORT_LOCALES = ['en', 'uk']
export const DEFAULT_LOCALE = "en";

const messages = (() => {
    const locales = require.context('.', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
})();
const i18n = createI18n(
    {
        locale: DEFAULT_LOCALE,
        fallbackLocale: 'en',
        messages: messages,
        numberFormats: {
            'it' : {
                currency: {
                    style: 'currency', currency: 'EUR', notation: 'standard'
                },
                decimal: {
                    style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2, useGrouping: true,
                },
                percent: {
                    style: 'percent', useGrouping: false
                }
            }
        },
        datetimeFormats: {
            'it' : {
                short: {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                },
                long: {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                }
            },
            'en': {
                medium: {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit'
                },
                long: {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                }
            }
        }
    });

const primeVueMessages = () => {
    let result = {};
    const allMessages = i18n.global.messages[i18n.global.locale];
    const allKeys = Object.keys(allMessages).filter(k => k.startsWith("primevue"));
    allKeys.forEach((k) => result[k.replace('primevue.', '')] = allMessages[k]);
    return result;
};

export {i18n, primeVueMessages};
