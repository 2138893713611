import moment from "moment";
import {useI18n} from "vue-i18n";
import {isNil} from "lodash/lang";


export default function () {

    const {d} = useI18n();

    /**
     * Parsing della data consideranto che sia in formato utc
     *
     * @param utcDateAsString
     * @returns {null|Date}
     */
    const parseDate = (utcDateAsString) => {
        if (!isNil(utcDateAsString) && '' !== utcDateAsString) {
            return moment.utc(utcDateAsString).toDate();
        }
        return null;
    }

    /**
     * Formattazione di data in base al timezone del browser dell'utente
     * considerando che in input ci sia una data in formato UTC
     *
     * @param utcDate
     * @returns {string}
     */
    const dateFormat = (utcDate) => {
        if (!isNil(utcDate)) {
            const localTimezoneDate = moment.utc(utcDate).toDate();
            return d(localTimezoneDate, 'short', 'it');
        }
    }

    /**
     * Formattazione di data e ora in base al timezone del browser dell'utente
     * considerando che in input ci sia una data in formato UTC
     *
     * @param utcDateTime
     * @returns {string}
     */
    const dateTimeFormat = (utcDateTime) => {
        if (!isNil(utcDateTime)) {
            const localTimezoneDateTime = moment.utc(utcDateTime).toDate();
            return d(localTimezoneDateTime, 'long', 'it');
        }
    }

    /**
     * Verifica se quanto ricevuto in input è una data valida
     * @param rawDate
     * @returns {boolean}
     */
    const isDate = (rawDate) => {
        return moment(rawDate).isValid();
    }

    const isBefore = (date, checkDate) => {
        if (!isNil(date) && !isNil(checkDate)) {
            return moment(date).isBefore(checkDate);
        }
    }

    const isAfter = (date, checkDate) => {
        if (!isNil(date) && !isNil(checkDate)) {
            return moment(date).isAfter(checkDate);
        }
    }

    const isBetween = (compareDate, startDate, endDate) => {
        return moment(compareDate).isBetween(startDate, endDate, null, '[]');
    }

    const isToday = (date) => {
        const TODAY = moment(new Date());
        return moment(date).isSame(TODAY, "day");
    }

    const isTomorrow = (date) => {
        const TOMORROW = moment(new Date()).add(1, 'day');
        return moment(date).isSame(TOMORROW, "day");
    }

    const now = () => {
        return moment().toDate();
    }

    return {parseDate, dateFormat, dateTimeFormat, isToday, isTomorrow, isBetween, isBefore, isAfter, isDate, now}
}
