import {isNil} from "lodash/lang";
import chatRestService from "@/services/ChatRestService";


export default {
    namespaced: true,
    state() {
        return {
            users: [],
            defaultUser: {},
            conversazioni: {}
        }
    },
    getters: {
        users: (state) => state.users,
        defaultUser: (state) => state.defaultUser,
        conversazioni: (state) => state.conversazioni,
        messaggi: (state) => (selectedUser) => {
            return state.conversazioni[selectedUser];
        },
        unreadMessagesNum: (state) => {
            let unreadMessages = 0;
            for (let user of state.users) {
                unreadMessages += user.unreadMessages;
            }
            return unreadMessages;
        }
    },
    actions: {
        setDefaultUser: async ({commit}, user) => {
            commit('saveDefaultUser', user);
        },
        loadUsers: async ({commit}) => {
            const users = await chatRestService.availableUsers();
            for (let user of users) {
                user.unreadMessages = 0;
            }
            commit('saveUsers', users);

            if (null != users && users.length > 0) {
                commit('saveDefaultUser', users[0]);
            }
        },
        resetUnreadMessages: ({commit}, user) => {
            commit('resetUnreadMessages', user.username);
        },
        addMessaggio: async ({commit}, messaggio) => {
            commit('saveMessaggio', messaggio);
        }
    },
    mutations: {
        saveMessaggio(state, content) {
            const isFromCurrentUser = content.username === content.messaggio.sender;
            const messageKey = isFromCurrentUser ? content.messaggio.recipient : content.messaggio.sender;

            if (isNil(state.conversazioni[messageKey])) {
                state.conversazioni[messageKey] = [];
            }
            state.conversazioni[messageKey].push(content.messaggio);

            // update users
            for (let user of state.users) {
                if (!isFromCurrentUser && user.username === messageKey) {
                    user.unreadMessages = user.unreadMessages + 1;
                }
            }
        },
        resetUnreadMessages(state, username) {
            for (let user of state.users) {
                if (user.username === username) {
                    user.unreadMessages = 0;
                }
            }
        },
        saveConversazione(state, sender) {
            if (isNil(state.conversazioni[sender])) {
                state.conversazioni[sender] = [];
            }
        },
        saveUsers(state, users) {
            state.users = users;
        },
        saveDefaultUser(state, user) {
            state.defaultUser = user;
        }
    }
}