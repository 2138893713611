import useRestApi from "@/composable/useRestService";
import httpClient from "@/services/HttpService";

const {findAll, findAllAttivi, insert, update, deleteRequest} = useRestApi('muta');

const findCarriByMuta = async (muta) => {
    return (await httpClient.get('/muta/' + muta.id + "/composizione")).data;
}

const saveComposizioneTreno = async(muta, carri) => {
    return (await httpClient.post('/muta/' + muta.id + "/composizione", carri)).data;
}

const totali = async (carri) => {
    return (await httpClient.post("/muta/composizione/totali", carri)).data;
}

const importMute = async (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.files.length; i++) {
        formData.append("files", files.files[i]);
    }
    return (await httpClient.post(`/muta/import`, formData)).data;
}

export default {
    findAll, findAllAttivi, findCarriByMuta, insert, update, deleteRequest, saveComposizioneTreno, totali, importMute
}


