import restService from "@/services/UtenteRestService";

export default {
    namespaced: true,
    state() {
        return {
            utenti: []
        }
    },
    getters: {
        utenti: (state) => state.utenti,
        utentiCount: (state) => state.utenti.length
    },
    actions: {
        loadAll: async ({commit}) => {
            const utenti = (await restService.users()).data;
            commit("saveUtenti", utenti);
        },
        insert: async ({dispatch}, item) => {
            await restService.insert(item)
                .then(() => dispatch('loadAll'))
                .catch(e => Promise.reject(e));
        },
        enable: async ({dispatch}, user) => {
            await restService.enable(user);
            dispatch('loadAll');
        },
        disable: async ({dispatch}, user) => {
            await restService.disable(user);
            dispatch('loadAll');
        }
    },
    mutations: {
        resetUtenti(state) {
            state.utenti = [];
        },
        saveUtenti(state, utenti) {
            state.utenti = utenti;
        }
    }
}