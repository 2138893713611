<template>
  <div class="input-errors" v-for="error of errors" :key="error.$uid">
    <div class="error-msg">{{ error.$message }}</div>
  </div>
</template>

<script setup>
import {computed, inject} from "vue";

const props = defineProps({field: String});
let v$ = inject('v$');

const errors = computed(() => (null != v$.value[props.field]) ? v$.value[props.field].$errors : []);
</script>

<style scoped>
.input-errors {
  margin: 2px 0 2px 0;
}

.error-msg {
  color: red;
}
</style>
