import store from '@/store';

function checkPermission(el, binding, vnode, store) {
    const {value} = binding;
    const roles = store.getters && store.getters["auth/loggedUser"].roles;

    if (value && value instanceof Array) {
        if (value.length > 0) {
            const permissionRoles = value;

            const hasPermission = roles.some(role => {
                return permissionRoles.includes(role)
            })

            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el);
            }
        }
    } else {
        throw new Error(`need roles! Like v-permission="['admin','editor']"`)
    }
}

export default {
    mounted(el, binding, vnode) {
        checkPermission(el, binding, vnode, store)
    },
    updated(el, binding, vnode) {
        checkPermission(el, binding, vnode, store)
    }
}