import {createStore} from 'vuex'

import createPersistedState from "vuex-persistedstate";
import auth from "@/store/modules/auth";
import contratto from "@/store/modules/contratto"
import soggetto from "@/store/modules/soggetto";
import nodo from "@/store/modules/nodo";
import paese from "@/store/modules/paese";
import config from "@/store/modules/config";
import utente from "@/store/modules/utente";
import contrattoWizard from "@/store/modules/contrattoWizard";
import carro from "@/store/modules/carro";
import muta from "@/store/modules/muta";
import viaggio from "@/store/modules/viaggio";
import chat from "@/store/modules/chat";
import notifiche from "@/store/modules/notifiche";
//import workflow from "@/store/modules/workflow";

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
    modules: {
        config, auth, paese, contrattoWizard, chat, notifiche,
        contratto, soggetto, nodo, utente, carro, muta, viaggio,   // TODO: rest api dirette
        //workflow,
    },
    strict: debug,
    plugins: [createPersistedState({storage: window.sessionStorage, paths: ["contrattoWizard"]})]
})
