import restService from "@/services/PaeseRestService";

export default {
    namespaced: true,
    state() {
        return {
            items: []
        }
    },
    getters: {
        items: (state) => state.items
    },
    actions: {
        loadAll: async ({commit}) => {
            const items = (await restService.items()).data;
            commit("saveItems", items);
        }
    },
    mutations: {
        saveItems(state, items) {
            state.items = items;
        }
    }
}