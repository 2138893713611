<template>
  <ModalDialog v-model:visible="show"
               :header="t('label.mail.composer.titolo')"
               position="bottomright"
               :draggable="true"
               :maximizable="false"
               :modal="false"
               @hide="onHide"
               style="width: 600px; height: 550px; z-index: 999999999">

    <div class="p-fluid formgrid grid">

      <!-- destinatario -->
      <div class="field col-12 md:col-12">
        <label for="destinatario">{{ t('label.mail.composer.destinatario') }}</label>
        <Dropdown id="destinatario" v-model="content.destinatario" option-label="username" option-value="email"
                  :options="utenti" :filter="true" :show-clear="true"/>
        <ErrorMessages field="destinatario"/>
      </div>

      <!-- soggetto -->
      <div class="field col-12 md:col-12">
        <label for="soggetto">{{ t('label.mail.composer.soggetto') }}</label>
        <InputText id="soggetto" v-model="content.soggetto" type="text"/>
        <ErrorMessages field="soggetto"/>
      </div>

      <!-- messaggio -->
      <div class="field col-12 md:col-12">
        <label for="messaggio">{{ t('label.mail.composer.messaggio') }}</label>
        <Textarea id="messaggio" rows="8" :autoResize="true" v-model="content.messaggio" style="resize: none"/>
        <ErrorMessages field="messaggio"/>
      </div>

      <!-- send -->
      <div class="field col-12 md:col-3 text-right" style="text-align: right">
        <Button :label="t('label.mail.composer.azione.invia')" @click="sendMail" class="p-button-raised"/>
      </div>

    </div>
  </ModalDialog>
</template>

<script setup>
import ModalDialog from "@/components/common/ModalDialog";
import {onMounted, provide, ref} from "vue";
import EventService from "@/services/EventService";
import {SHOW_MAIL} from "@/components/common/constants/Events";
import {useI18n} from "vue-i18n";
import chatRestService from "@/services/ChatRestService";
import mailRestService from "@/services/MailRestService";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import ErrorMessages from "@/components/common/ErrorMessages";



    const {t} = useI18n();
    const show = ref(false);

    const utenti = ref([]);

    const getEmptyContent = () => {
      return {
        destinatario: "",
        soggetto: "",
        messaggio: ""
      }
    }

    const content = ref(getEmptyContent());

    const rules = {
      destinatario: {required: helpers.withMessage(t("validation.required", {field: t("label.mail.composer.destinatario")}), required)},
      soggetto: {required: helpers.withMessage(t("validation.required", {field: t("label.mail.composer.soggetto")}), required)},
      messaggio: {required: helpers.withMessage(t("validation.required", {field: t("label.mail.composer.messaggio")}), required)},
    };
    const v$ = useVuelidate(rules, content);

    onMounted(async () => {
      utenti.value = await chatRestService.availableUsers();
      EventService.on(SHOW_MAIL, onShow);
    })

    const onShow = () => {
      show.value = true;
    }

    const onHide = () => {
      v$.value.$reset();
      content.value = getEmptyContent();
    }
    

    const sendMail = async () => {
      await v$.value.$validate();
      if (!v$.value.$invalid) {
        await mailRestService.sendMail(content.value);
        show.value = false;
      } else {
        console.log("-----INVALID ", v$.value.$errors);
      }
    }

    provide("v$", v$);
</script>

<style scoped>

</style>
