<template>
  <div class="chat-content">

    <div class="chat-message" :class="{'sent': message.currentUser, 'received': !message.currentUser}"
         v-for="message in messages" :key="message">
      <div class="message">
        <p>{{ message.content }}</p>
        <span class="message-timestamp">{{ dateTimeFormat(message.datetime) }}</span>
      </div>
    </div>

  </div>
</template>

<script setup>
import useDateTime from "@/composable/useDateTime";

defineProps({messages: Array});
const {dateTimeFormat} = useDateTime();

</script>

<style lang="scss" scoped>

$received: #f6f6f6;
$sent: #ebfce4;

.chat-content {
  padding: 6px;
}

.chat-message {
  width: 400px;
  padding: 10px;
}

.received {
  position: relative;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: $received;
  width: 200px;
  height: 50px;
  text-align: left;
  border: 1px solid $received;
  border-radius: 10px;
}

.received:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid $received;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 0;
  left: -15px;
}

.received:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 17px solid $received;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  top: -1px;
  left: -17px;
}


.sent {
  position: relative;
  margin-bottom: 10px;
  margin-left: calc(100% - 200px);
  margin-right: 5px;
  padding: 10px;
  background-color: $sent;
  width: 200px;
  height: 50px;
  text-align: left;
  border: 1px solid $sent;
  border-radius: 10px;
}


.sent:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 15px solid $sent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  bottom: 0;
  right: -15px;
}

.sent:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 17px solid $sent;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  bottom: -1px;
  right: -17px;
}

.message-timestamp {
  position: absolute;
  font-size: .85em;
  font-weight: 300;
  bottom: 5px;
  right: 5px;
}

</style>
