import restService from "@/services/ViaggioRestService";

export default {
    namespaced: true,
    state() {
        return {
            viaggi: []
        }
    },
    getters: {
        viaggi: (state) => state.viaggi
    },
    actions: {
        loadAll: async ({commit}) => {
            const viaggi = (await restService.items()).data;
            commit("saveViaggi", viaggi);
        },
        insert: async ({dispatch}, item) => {
            await restService.insert(item);
            dispatch('loadAll');
        },
        update: async ({dispatch}, item) => {
            await restService.update(item);
            dispatch('loadAll');
        },
        delete: async ({dispatch}, item) => {
            await restService.delete(item);
            dispatch('loadAll');
        }
    },
    mutations: {
        saveViaggi(state, viaggi) {
            state.viaggi = viaggi;
        }
    }
}
