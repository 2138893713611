<template>
  <ul v-if="items" role="menu">
    <template v-for="(item, i) of items">
      <li v-if="visible(item) && !item.separator" :key="item.label || i"
          :class="[{'layout-root-menuitem': root, 'active-menuitem': activeIndex === i && !item.disabled}]"
          role="menuitem">
        <router-link v-if="item.to" :to="item.to" :style="item.style"
                     :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]" active-class="active-route"
                     :target="item.target"
                     @click="onMenuItemClick($event, item, i)"
                     @mouseenter="onMenuItemMouseEnter(i)" v-ripple>
          <i :class="['layout-menuitem-icon', item.icon]"></i>
          <span class="layout-menuitem-text">{{ item.label }}</span>
          <i v-if="item.items" class="pi pi-fw pi-chevron-down layout-submenu-toggler"></i>
        </router-link>
        <a v-if="!item.to" :href="item.url || '#'" :style="item.style"
           :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
           :target="item.target" @click="onMenuItemClick($event, item, i)" @mouseenter="onMenuItemMouseEnter(i)"
           v-ripple>
          <i :class="['layout-menuitem-icon', item.icon]"></i>
          <span class="layout-menuitem-text">{{ item.label }}</span>
          <i v-if="item.items" class="pi pi-fw pi-chevron-down layout-submenu-toggler"></i>
        </a>
        <div class="layout-menu-tooltip">
          <div class="layout-menu-tooltip-arrow"></div>
          <div class="layout-menu-tooltip-text">{{ item.label }}</div>
        </div>
        <transition name="layout-menu">
          <appSubmenu v-show="activeIndex === i" :items="visible(item) && item.items"
                      :menuActive="menuActive" :layoutMode="layoutMode" :parentMenuItemActive="activeIndex === i"
                      @menuitem-click="$emit('menuitem-click', $event)"></appSubmenu>
        </transition>
      </li>
    </template>
  </ul>
</template>

<script setup>
import EventService from "@/services/EventService";
import {onMounted, ref} from "vue";
import useBrowser from "@/composable/useBrowser";

const emit = defineEmits(["root-menuitem-click", "menuitem-click"])
const props = defineProps({
  items: Array,
  layoutMode: String,
  menuActive: Boolean,
  mobileMenuActive: Boolean,
  root: {
    type: Boolean,
    default: false,
  },
  parentMenuItemActive: {
    type: Boolean,
    default: false,
  },
})

const {isMobile} = useBrowser();

const activeIndex = ref(null);

onMounted(() => {
  EventService.on('reset-active-index', () => {
    if (isSlim() || isHorizontal()) {
      activeIndex.value = null;
    }
  });
})



const onMenuItemClick = (event, item, index) => {
  if (item.disabled) {
    event.preventDefault();
    return;
  }
  //execute command
  if (item.command) {
    item.command({originalEvent: event, item: item});
    event.preventDefault();
  }
  if (item.items) {
    event.preventDefault();
  }
  if (props.root) {
    emit("root-menuitem-click", {
      originalEvent: event,
    });
  }
  if (item.items) {
    activeIndex.value = index === activeIndex.value ? null : index;
  } else {
    activeIndex.value = index;

    if (props.layoutMode !== 'sidebar') {
      const ink = getInk(event.currentTarget);
      if (ink) {
        removeClass(ink, 'p-ink-active');
      }
    }
  }
  emit("menuitem-click", {
    originalEvent: event,
    item: item,
  });
}


const onMenuItemMouseEnter = (index) => {
  if (props.root && props.menuActive && (isHorizontal() || isSlim()) && !isMobile()) {
    activeIndex.value = index;
  }
}

const visible = (item) => {
  return typeof item.visible === "function" ? item.visible() : item.visible !== false;
}

const isSlim = () => {
  return props.layoutMode === 'slim';
}

const isHorizontal = () => {
  return props.layoutMode === 'horizontal';
}

const getInk = (el) => {
  for (let i = 0; i < el.children.length; i++) {
    if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
      return el.children[i];
    }
  }
  return null;
}

const removeClass = (element, className) => {
  if (element.classList)
    element.classList.remove(className);
  else
    element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
}

</script>
