import mitt from "mitt";

const emitter = new mitt();
export default {
    emit(type, params) {
        emitter.emit(type, params);
    },
    on(event, handler) {
        emitter.on(event, handler);
    },
    off(event, handler) {
        emitter.off(event, handler);
    }
}