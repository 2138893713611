<template>
  <Dialog v-model:visible="visibility"
          position="top"
          :modal="true"
          :draggable="false"
          :maximizable="true"
          class="p-fluid p-dialog-sidepanel"
          v-bind="$attrs">

    <template #header>
      <slot name="header"/>
    </template>

    <slot/>

    <template #footer>
      <slot name="footer"/>
    </template>

  </Dialog>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  visible: {type: Boolean, required: false},
  hide: {type: Function, required: false}
});

const emit = defineEmits(['update:visible']);

const visibility = computed({
  get: () => props.visible,
  set: (value) => {
    emit('update:visible', value);
  }
});

</script>

<style scoped>

</style>
