<template>
  <span @click="showNotifiche" class="mr-4">

    <!-- nessun messaggio -->
    <i class="pi pi-bell notifiche nomsg"
       v-tooltip.bottom="t('label.notifiche.assenti')"
       style="font-size: 2rem;"
       v-if="numNotificheNonLette === 0"/>

    <!-- messaggi ricevuti -->
     <i class="pi pi-bell notifiche moremsg"
        v-tooltip.bottom="t('label.notifiche', 2)"
        v-badge.info="numNotificheNonLette"
        style="font-size: 2rem;"
        v-if="numNotificheNonLette > 0"/>
  </span>

</template>

<script setup>
import {useI18n} from "vue-i18n";

import EventService from "@/services/EventService";
import {computed} from "vue";
import {useStore} from "vuex";

const store = useStore();
const {t} = useI18n();

const numNotificheNonLette = computed(() => store.getters["notifiche/numNotificheNonLette"]);

const showNotifiche = () => {
  store.dispatch("notifiche/segnaComeLette");
  EventService.emit("notifications");
};
</script>

<style lang="scss" scoped>
.notifiche {
  cursor: pointer;
}

.nomsg {
  color: #5297ff;
  filter: brightness(0.80);
  opacity: 0.8;
}

.moremsg {
  color: #5297ff;
}


</style>
