import EventService from "@/services/EventService";
import {WORKFLOW_NOTIFICATION} from "@/components/common/constants/Events";

export default {
    namespaced: true,
    state() {
        return {
            notifiche: []
        }
    },
    getters: {
        notifiche: (state) => state.notifiche,
        numNotifiche: (state) => state.notifiche.length,
        numNotificheNonLette: (state) => state.notifiche.filter(n => !n.readed).length
    },
    actions: {
        add: async ({commit}, notifica) => {
            commit('saveNotifica', notifica);
        },
        segnaComeLette: async ({commit}) => {
            commit('setLette');
        },
        reset: async ({commit}) => {
            commit('resetNotifiche');
        }
    },
    mutations: {
        saveNotifica(state, notifica) {
            state.notifiche.push(notifica);
            state.notifiche = state.notifiche.sort((a,b) => {
                return new Date(b.dateTime) - new Date(a.dateTime);
            });
            EventService.emit(WORKFLOW_NOTIFICATION, notifica);
        },
        setLette(state) {
            for (let notifica of state.notifiche) {
                notifica.readed = true;
            }
        },
        resetNotifiche(state) {
            state.notifiche = [];
        }
    }
}
