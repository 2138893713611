<template>
  <div class="layout-rightpanel" @click="emit('update:rightMenuClick', true)">
    <div class="rightpanel-wrapper">

      <div class="rightpanel-section tasks-section">
        <div class="section-header">
          <h3 v-if="numNotifiche > 0">{{ numNotifiche }} {{ t('label.notifiche', numNotifiche) }} </h3>
          <h3 v-else>{{t('label.notifiche.assenti')}}</h3>
        </div>
        <ul v-for="notifica in notifiche" v-bind:key="notifica">
          <!--<li :class="{'done': notifica.readed}">-->
          <li>
          <div class="task-info" style="width: 100%">
              <span>
                <i class="pi pi-info-circle"></i>
                {{ notifica.title }}
                <span class="p-float-right">{{ dateTimeFormat(notifica.dateTime) }}</span>
              </span>

              <div>{{ notifica.message }}</div>

              <!-- action -->
              <div v-if="null != notifica.params && null != notifica.params.action" class="p-text-right">
                <Button :label="t('label.details')"
                        icon="pi pi-directions"
                        class="p-button-link"
                        @click="executeAction(notifica)"/>
              </div>

            </div>

            <!--<i class="pi pi-check" v-if="notifica.readed"></i>-->
          </li>

        </ul>
      </div>


    </div>
  </div>
</template>

<script setup>
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import useDateTime from "@/composable/useDateTime";
import router from "@/router";
import EventService from "@/services/EventService";

const emit = defineEmits(["right-menu-click", "update:rightMenuClick"])
defineProps({rightMenuActive: {type: Boolean, required: true}});

const numNotifiche = computed(() => store.getters["notifiche/numNotifiche"]);
const notifiche = computed(() => store.getters["notifiche/notifiche"]);

const {t} = useI18n();
const {dateTimeFormat} = useDateTime();
const store = useStore();


function executeAction(notifica) {
  const action = notifica.params.action;

  if ('show_contratto' === action) {
    router.push("/contratti");
    EventService.emit("notifications");
  }

}


</script>
