import restService from "@/services/ContrattoRestService";

export default {
    namespaced: true,
    state() {
        return {
            items: [],
            drafts: [],
            uploadedFiles: [],
            changes: [],
            nhms: [],
            currencies: [],
            locodes: [],
            incoterms: []
        }
    },
    getters: {
        items: (state) => state.items,
        drafts: (state) => state.drafts,
        ultimiContratti: (state) => (numItems) => state.items.slice(0, numItems),
        uploadedFiles: (state) => state.uploadedFiles,
        tipiControlloPeso: (state) => state.tipiControlloPeso,
        tipiControlloQualita: (state) => state.tipiControlloQualita,
        changes: (state) => state.changes,
        nhms: (state) => state.nhms,
        currencies: (state) => state.currencies,
        locodes: (state) => state.locodes,
        incoterms: (state) => state.incoterms
    },
    actions: {
        loadAll: async ({commit}) => {
            let contratti = (await restService.items()).data;
            commit("saveItems", contratti);
        },
        loadAllDrafts: async ({commit}) => {
            const drafts = (await restService.drafts()).data;
            commit("saveDrafts", drafts);
        },
        loadAllUploadedFiles: async ({commit}, item) => {
            const uploadedFiles = (await (restService.uploadedFiles(item))).data;
            commit("saveUploadedFiles", uploadedFiles);
        },
        update: async ({dispatch}, item) => {
            await restService.update(item);
            dispatch('loadAll');
        },
        updateCodiceSeller: async ({dispatch}, item) => {
            await restService.updateCodiceSeller(item);
            dispatch('loadAll');
        },
        updateCodiceBuyer: async ({dispatch}, item) => {
            await restService.updateCodiceBuyer(item);
            dispatch('loadAll');
        },
        updateCodiceContrattoForwarding: async ({dispatch}, item) => {
            await restService.updateCodiceContrattoForwarding(item);
            dispatch('loadAll');
        },
        insert: async ({dispatch}, item) => {
            const response = await restService.insert(item);
            const contratto = response.data;
            await dispatch('loadAll');
            return contratto;
        },
        saveDraft: async ({dispatch}, draft) => {
            await restService.saveDraft(draft);
            await dispatch('loadAllDrafts');
        },
        changeStatus: async ({commit}, data) => {
            await commit('saveStatus', data);
        },
        searchNhms: async ({commit}, term) => {
            const nhms = (await restService.searchNhms(term)).data;
            await commit('saveNhms', nhms);
        },
        loadCurrencies: async ({commit}) => {
            const currencies = (await restService.loadCurrencies()).data;
            await commit('saveCurrencies', currencies);
        },
        searchLocodes: async ({commit}, query) => {
            const locodes = (await restService.searchLocodes(query)).data;
            await commit('saveLocodes', locodes);
        },
        searchIncoterms: async ({commit}, query) => {
            const incoterms = (await restService.searchIncoterms(query)).data;
            await commit('saveIncoterms', incoterms);
        },
        uploadFiles: async ({dispatch}, params) => {
            await restService.uploadFiles(params.contratto, params.documentType, params.files);
            await dispatch('loadAllUploadedFiles', params.contratto);
        }
    },
    mutations: {
        saveItems(state, items) {
            state.items = items;
        },
        saveDrafts(state, drafts) {
            state.drafts = drafts;
        },
        saveUploadedFiles(state, uploadedFiles) {
            state.uploadedFiles = uploadedFiles;
        },
        saveStatus(state, data) {
            state.items[0].status = data.status;
        },
        saveChanges(state, data) {
            state.changes = data;
        },
        saveNhms(state, data) {
            state.nhms = data;
        },
        saveCurrencies(state, currencies) {
            state.currencies = currencies;
        },
        saveLocodes(state, locodes) {
            state.locodes = locodes;
        },
        saveIncoterms(state, incoterms) {
            state.incoterms = incoterms;
        }
    }
}
