import restService from "@/services/SoggettoRestService";

export default {
    namespaced: true,
    state() {
        return {
            items: [],
            brokers: [],
            buyers: [],
            sellers: []
        }
    },
    getters: {
        items: (state) => state.items,
        brokers: (state) => state.brokers,
        buyers: (state) => state.buyers,
        sellers: (state) => state.sellers
    },
    actions: {
        loadAll: async ({commit}) => {
            const items = (await restService.items()).data;
            commit("saveItems", items);
        },
        loadAllBrokers: async ({commit}) => {
            const items = (await restService.brokers()).data;
            commit("saveBrokers", items);
        },
        loadAllBuyers: async ({commit}) => {
            const items = (await restService.buyers()).data;
            commit("saveBuyers", items);
        },
        loadAllSellers: async ({commit}) => {
            const items = (await restService.sellers()).data;
            commit("saveSellers", items);
        },
        delete: async ({dispatch}, item) => {
            await restService.delete(item);
            dispatch('loadAll');
        }
    },
    mutations: {
        saveItems(state, items) {
            state.items = items;
        },
        saveBrokers(state, items) {
            state.brokers = items;
        },
        saveBuyers(state, items) {
            state.buyers = items;
        },
        saveSellers(state, items) {
            state.sellers = items;
        }
    }
}
