<template>
    <NotFound v-if="$route.path === '/notfound'" />
    <App v-else :topbarTheme="topbarTheme" :menuTheme="menuTheme" @change-component-theme="changeComponentTheme" @change-color-scheme="changeColorScheme"
        @topbar-theme="onTopbarThemeChange" @menu-theme="onMenuThemeChange" />
</template>

<script setup>
    import {ref, getCurrentInstance} from "vue";
    import useBrowser from '@/composable/useBrowser';
    import App from './App.vue';
    import NotFound from './pages/NotFound';

    const {isIE} = useBrowser();
    const appState = getCurrentInstance().appContext.config.globalProperties.$appState;

    const topbarTheme = ref("light");
    const menuTheme = ref("light");


    const onTopbarThemeChange = (theme) => {
      topbarTheme.value = theme;
    }

    const onMenuThemeChange = (theme) => {
      menuTheme.value = theme;
    }

    const changeComponentTheme = (theme) => {
      changeStyleSheetUrl("theme-css", theme, 3);
    }

    const changeColorScheme = (scheme) => {
      topbarTheme.value = scheme;
      menuTheme.value = scheme;

      changeStyleSheetUrl("layout-css", "layout-" + scheme + ".css", 1);
      changeStyleSheetUrl("theme-css", "theme-" + scheme + ".css", 1);
    }

    const changeStyleSheetUrl = (id, value, from) => {
      const element = document.getElementById(id);
      const urlTokens = element.getAttribute("href").split("/");

      if (from === 1) {
        // which function invoked this function
        urlTokens[urlTokens.length - 1] = value;
      } else if (from === 2) {
        // which function invoked this function
        if (value !== null) {
          urlTokens[urlTokens.length - 2] = value;
        }
      } else if (from === 3) {
        // which function invoked this function
        urlTokens[urlTokens.length - 2] = value;
      }

      const newURL = urlTokens.join("/");

      replaceLink(element, newURL, () => {
        appState.isNewThemeLoaded = true;
      });
    }

    const replaceLink = (linkElement, href, callback) => {
      if (isIE()) {
        linkElement.setAttribute("href", href);
      } else {
        const id = linkElement.getAttribute("id");
        const cloneLinkElement = linkElement.cloneNode(true);

        cloneLinkElement.setAttribute("href", href);
        cloneLinkElement.setAttribute("id", id + "-clone");

        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

        cloneLinkElement.addEventListener("load", () => {
          linkElement.remove();
          cloneLinkElement.setAttribute("id", id);

          if(callback) {
            callback();
          }
        });
      }
    }


</script>

<style scoped>
</style>
