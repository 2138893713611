<template>
  <div class="exception-body notfound">
    <div class="exception-topbar">
      <a id="logolink" @click="goHome" class="layout-topbar-logo">
        <img src="@/assets/img/logo-full.svg"/>
      </a>
    </div>
    <div class="exception-wrapper">
      <div class="exception-content">
        <img src="@/assets/layout/images/pages/asset-404.svg"/>
        <span>404</span>
        <span class="exception-subtitle">{{ t('message.page-not-found') }}</span>
        <Button type="button" :label="t('action.go-back-home')" @click="goHome"></button>
      </div>
      <div class="exception-footer p-mt-4">
        <h4>{{ t('project.name') }}</h4>
        <h6>Copyright Ⓒ {{ t('project.company') }}</h6>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
import {useI18n} from "vue-i18n";

const {t} = useI18n();

const goHome = () => {
  router.push('/dashboard');
}

</script>
