export default function () {

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    }

    const isMobile = () => {
        return window.innerWidth <= 991;
    }


    return {isIE, isMobile};
}
