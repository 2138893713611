<template>
    <div class="layout-footer">
        <div class="grid">
            <div class="col-12">
                <div class="footer-bottom">
                    <h4>{{t('project.name')}} - {{t('project.version')}}</h4>
                    <h6>Copyright Ⓒ {{ t('project.company') }}</h6>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n();

</script>
