import httpClient from "@/services/HttpService";

export default {
    users: () => {
        return httpClient.get('/utente/all');
    },
    usersByEori: (eori) => {
        return httpClient.get('/utente/all/eori/' + eori);
    },
    insert: (user) => {
        return httpClient.post('/utente/insert', user);
    },
    enable: (user) => {
        return httpClient.post('/utente/enable', user);
    },
    disable: (user) => {
        return httpClient.post('/utente/disable', user);
    },
    impersonate: async (user) => {
        return (await httpClient.post('/utente/impersonate', user)).data;
    },
    findExecutionManager: async (id) => {
        return (await httpClient.get(`/utente/execution-manager/${id}`)).data;
    },
    findExmanByUsername: async (username) => {
        return (await httpClient.get(`/utente/exman/username/${username}`)).data;
    },
    findByUsername: async (username) => {
        return (await httpClient.get(`/utente/all/username/${username}`)).data;
    },
    insertExecutionManager: async (data) => {
        return (await httpClient.post('/utente/insert/executionmanager', data)).data;
    },
    insertSpedizioniereFerroviario: async (data) => {
        return (await httpClient.post('/utente/insert/spedizioniere', data)).data;
    }
}
