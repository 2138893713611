import httpClient from "@/services/HttpService";

export default {

    items: () => {
        return httpClient.get('/soggetto/all');
    },
    brokers: () => {
        return httpClient.get('/soggetto/all/brokers');
    },
    buyers: () => {
        return httpClient.get('/soggetto/all/buyers');
    },
    spedizionieri: async () => {
        return (await httpClient.get('/soggetto/all/spedizionieri')).data;
    },
    caricatori: async () => {
        return (await httpClient.get('/soggetto/all/caricatori')).data;
    },
    scaricatori: async () => {
        return (await httpClient.get('/soggetto/all/scaricatori')).data;
    },
    surveyors: async () => {
        return (await httpClient.get('/soggetto/all/surveyors')).data;
    },
    sellers: () => {
        return httpClient.get('/soggetto/all/sellers');
    },
    customBrokers: async () => {
        return (await httpClient.get(`/soggetto/all/custom-brokers`)).data;
    },
    impreseFerroviarie: async () => {
        return (await httpClient.get(`/soggetto/all/imprese-ferroviarie`)).data;
    },
    delete: (item) => {
        return httpClient.post('/soggetto/delete', item);
    }

}
