import restService from "@/services/AuthRestService";
import router from "@/router/index";
import {i18n} from '@/i18n/i18n';
import {isEmpty} from "lodash/lang";
import Roles from "@/components/common/constants/Roles";

export default {
    namespaced: true,
    state() {
        return {
            user: {},
            isAuthenticated: false
        }
    },
    getters: {
        loggedUser: state => state.user,
        locale: state => state.user.locale,
        username: state => state.user.username,
        isAuthenticated: state => state.isAuthenticated,
        isAdmin: state => !isEmpty(state.user.roles) ? state.user.roles.includes(Roles.ADMIN) : false,
        isBroker: state => !isEmpty(state.user.roles) ? state.user.roles.includes(Roles.BROKER) : false,
        isSeller: state => !isEmpty(state.user.roles) ? state.user.roles.includes(Roles.SELLER) : false,
        isBuyer: state => !isEmpty(state.user.roles) ? state.user.roles.includes(Roles.BUYER) : false,
        isExecutionManager: state => !isEmpty(state.user.roles) ? state.user.roles.includes(Roles.EXECUTION_MANAGER) : false,
        isSpedizioniere: state => !isEmpty(state.user.roles) ? state.user.roles.includes(Roles.SPEDIZIONIERE) : false,
        isCaricatore: state => !isEmpty(state.user.roles) ? state.user.roles.includes(Roles.CARICATORE) : false,
        isScaricatore: state =>  !isEmpty(state.user.roles) ? state.user.roles.includes(Roles.SCARICATORE) : false,
    },
    actions: {
        logout: async ({commit}) => {
            commit('clearUserData');
        },
        fetchUser: async ({commit}) => {
            const result = await restService.user();
            const data = result.data;
            i18n.global.locale = data.locale;

            // translate menu labels and menu items (first level only)
            let menu = data.menu;
            for (let i = 0; i < menu.length; i++) {
                menu[i].label = i18n.global.tc(menu[i].label, 2);

                let items = menu[i].items;
                if (null != items && items.length > 0) {
                    for (let j = 0; j < items.length; j++) {
                        let item = items[j];
                        item.label = i18n.global.tc(item.label, 2);
                        items[j] = item;
                    }
                }
                menu[i].items = items;
            }
            data.menu = menu;
            commit('setUser', data);

        },
        setLocale: async ({commit}, locale) => {
            commit('setLocale', locale);
        },
        user: async () => {
            await router.push("utente");
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            state.isAuthenticated = true;
        },
        setLocale(state, locale){
            state.user.locale = locale;
        }
    }
}
