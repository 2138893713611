import httpClient from "@/services/HttpService";
import {saveAs} from 'file-saver';
import {isEmpty} from "lodash";

export default function useRestApi(context) {

    const findAll = async () => {
        return (await httpClient.get('/' + context + '/')).data;
    };

    const findAllAttivi = async () => {
        return (await httpClient.get('/' + context + '/attivo')).data;
    };

    const insert = async (item) => {
        return (await httpClient.post('/' + context + '/', item)).data;
    };

    const update = async (item) => {
        return (await httpClient.put('/' + context + '/', item)).data;
    };

    const deleteRequest = async (item) => {
        return (await httpClient.delete('/' + context + '/', {data: item})).data
    };

    const fileDownload = async (url) => {
        const res = await httpClient.get(url, {responseType: 'blob'});
        const headerLine = res.headers['content-disposition'];
        if(!isEmpty(headerLine)) {
            const startFileNameIndex = headerLine.indexOf('"') + 1
            const endFileNameIndex = headerLine.lastIndexOf('"');
            const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
            saveAs(res.data, filename);
        }
    }

    const fileUpload = async (url, files) => {
        const formData = new FormData();
        for (let i = 0; i < files.files.length; i++) {
            formData.append("files", files.files[i]);
        }
        return httpClient.post(url, formData);
    }

    return {findAll, findAllAttivi, insert, update, deleteRequest, fileDownload, fileUpload}
}
