import restService from "@/services/MutaRestService";

const getDefaultState = () => {
    return {
        mute: [],
        muteAttive: [],
        composizioneTreno: []
    }
}

const state = getDefaultState();

const getters = {
    mute: (state) => state.mute,
    muteAttive: (state) => state.muteAttive,
    direzioni: (state) => state.direzioni
}

const actions = {
    loadAll: async ({commit}) => {
        const mute = await restService.findAll();
        commit('saveMute', mute);
    },
    loadAllAttivi: async ({commit}) => {
        const muteAttive = await restService.findAllAttivi();
        commit('saveMuteAttive', muteAttive);
    },
    insert: async ({dispatch}, muta) => {
        await restService.insert(muta);
        dispatch('loadAll');
    },
    update: async ({dispatch}, muta) => {
        await restService.update(muta);
        dispatch('loadAll');
    },
    delete: async ({dispatch}, muta) => {
        await restService.delete(muta);
        dispatch('loadAll');
    },
    saveComposizioneTreno: async ({commit}, params) => {
        const muta = params.muta;
        const carri = params.carri;
        await restService.saveComposizioneTreno(muta, carri);
        commit('saveComposizioneTreno', carri);
    }
}

const mutations = {
    saveMute(state, mute) {
        state.mute = mute;
    },
    saveMuteAttive(state, muteAttive) {
        state.muteAttive = muteAttive;
    },
    saveComposizioneTreno(state, composizioneTreno){
        state.composizioneTreno = composizioneTreno;
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
