<template>
  <div class="layout-topbar">

    <div class="layout-topbar-wrapper">
      <div class="layout-topbar-left">
        <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
          <i class="pi pi-bars"></i>
        </a>

        <router-link id="logo-link" class="layout-topbar-logo" to="/">
          <img src="@/assets/img/logo-full.svg"/>
        </router-link>
      </div>

      <AppMenu :layoutMode="layoutMode" :sidebarActive="sidebarActive" :sidebarStatic="sidebarStatic"
               :menuActive="menuActive" :mobileMenuActive="mobileMenuActive"
               @sidebar-mouse-leave="onSidebarMouseLeave" @sidebar-mouse-over="onSidebarMouseOver"
               @toggle-menu="onToggleMenu"
               @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"/>


      <div class="layout-topbar-right">
        <ul class="layout-topbar-actions">

          <!-- locale
          <li v-if="locale">
            <span v-if="locale === 'it'" :class="['flag flag-' + locale]"></span>
            <span v-else :class="['flag flag-uk']"></span>
          </li>-->
          <!--<li class="mr-2">
          <LocaleSwitcher />
          </li>-->

          <!-- chat -->
          <li class="ml-3 mr-2">
            <ChatToolbarButton/>
            <NotificheToolbarButton/>
            <LogoutToolbarButton />
          </li>

          <!-- user menu -->
          <li ref="profile" class="topbar-item user-profile"
              :class="{'active-topmenuitem fadeInDown': topbarUserMenuActive}">
            <a @click="onTopbarUserMenuClick" style="text-align: center" class="mt-1">
              <Avatar icon="pi pi-user" size="large" shape="circle" style="width: 2.5rem;height: 2.5rem;background-color:#5297ff; color: #ffffff"/>
              <div class="mt-1" style="font-size: 1.0rem; letter-spacing: 0.1rem">{{username}}</div>
            </a>
          </li>



        </ul>

      </div>
    </div>
    <ProgressBar mode="indeterminate" style="height: .3em" v-if="progressBar"/>
  </div>
</template>

<script setup>
import AppMenu from './AppMenu';
import {useStore} from "vuex";
import EventService from "@/services/EventService";
import ChatToolbarButton from "@/components/toolbar/ChatToolbarButton";
import NotificheToolbarButton from "@/components/toolbar/NotificheToolbarButton";
import {onMounted, onUnmounted, ref, computed} from "vue";
import LogoutToolbarButton from "@/components/toolbar/LogoutToolbarButton";
import router from "@/router";
// import LocaleSwitcher from "@/LocaleSwitcher";

const emit = defineEmits(["menu-click", "menuitem-click", "root-menuitem-click", "menu-button-click", "toggle-menu", "right-menubutton-click",
  "sidebar-mouse-over", "sidebar-mouse-leave", "topbar-search-toggle", "topbar-search-click", "topbar-search-hide",
  "topbar-usermenu-click", "update:searchClick"]);

defineProps({
  searchActive: Boolean,
  searchClick: Boolean,
  topbarItemClick: Boolean,
  topbarUserMenuActive: Boolean,
  topbarUserMenuClick: Boolean,
  activeTopbarItem: String,
  sidebarActive: Boolean,
  sidebarStatic: Boolean,
  layoutMode: String,
  topbarTheme: String,
  menuActive: Boolean,
  mobileMenuActive: Boolean
})

const store = useStore();


const progressBar = ref(false);

//const locale = computed(() => store.getters['auth/locale']);
const username = computed(() => store.getters['auth/username']);
// const usernameFirstChars = computed(() => {
//   return 'undefined' != typeof username.value ? username.value.substring(0, 3) : '';
// })

onMounted(() => {
  EventService.on("progress", (status) => {
    progressBar.value = status
  });
})

onUnmounted(() => {
  /* if (this.subscription) {
        this.subscription.unsubscribe();
      }*/
})



const onMenuClick = (event) => {
  emit("menu-click", event);
}

const onMenuItemClick = (event) => {
  emit("menuitem-click", event);
}

const onRootMenuItemClick = (event) => {
  emit("root-menuitem-click", event);
}

const onMenuButtonClick = (event) => {
  emit("menu-button-click", event);
}

const onToggleMenu = (event) => {
  emit("toggle-menu", event);
}

const onTopbarUserMenuClick = (event) => {
  emit("topbar-usermenu-click", event);
  router.push("/utente");
}

const onSidebarMouseOver = () => {
  emit("sidebar-mouse-over");
}

const onSidebarMouseLeave = () => {
  emit("sidebar-mouse-leave");
}

/*const onRightMenuButtonClick = (event) => {
  emit("right-menubutton-click", event);
}*/

/*const onTopbarSearchToggle = (event) => {
  emit('topbar-search-toggle', event);
  onSearchFocus();
}*/

/*const onTopbarSearchClick = (event) => {
  emit("topbar-search-click", event);
}*/

/*const onInputKeydown = (event) => {
  const key = event.which;

  //escape, tab and enter
  if (key === 27 || key === 9 || key === 13) {
    emit("topbar-search-hide", event);
  }
}*/

/*const onSearchFocus = () => {
  if (window.innerWidth >= 576) {
    this.$refs.desktopInput.$el.focus();
  } else {
    this.$nextTick(function () {
      this.$refs.phoneInput.$el.focus();
    })
  }
}*/

</script>

<style lang="scss" scoped>

.p-dropdown {
  min-width: 300px;
}

.p-dropdown-item {
  font-size: 50px;
  margin: 20px;
}

.avatar {
  background-color: #628dc9;
  color: #ffffff;
}

</style>
