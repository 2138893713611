<template>
  <div :class="containerClass" :data-theme="colorScheme" @click="onDocumentClick($event)">

    <teleport to="#toast">
      <Toast position="top-right" :baseZIndex="9999999999999"/>
    </teleport>

    <div class="layout-content-wrapper">
      <AppTopBar :layoutMode="layoutMode" :topbarTheme="topbarTheme" :menuActive="menuActive"
                 :mobileMenuActive="staticMenuMobileActive" :sidebarActive="sidebarActive"
                 :sidebarStatic="sidebarStatic"
                 :topbarUserMenuActive="topbarUserMenuActive" :topbarUserMenuClick="topbarUserMenuClick"
                 :searchActive="searchActive" :searchClick="searchClick" :topbarItemClick="topbarItemClick"
                 :activeTopbarItem="activeTopbarItem" @menu-click="onMenuClick" @menuitem-click="onMenuItemClick"
                 @root-menuitem-click="onRootMenuItemClick"
                 @menu-button-click="onMenuButtonClick" @right-menubutton-click="onRightMenuButtonClick"
                 @toggle-menu="onToggleMenu" @topbar-usermenu-click="onTopbarUserMenuClick"
                 @sidebar-mouse-leave="onSidebarMouseLeave" @sidebar-mouse-over="onSidebarMouseOver"
                 @topbar-search-toggle="onTopbarSearchToggle" @topbar-search-click="OnTopbarSearchClick"
                 @topbar-search-hide="onTopbarSearchHide"></AppTopBar>

      <div class="layout-main">
        <div class="layout-content">
          <router-view/>
        </div>
        <AppFooter/>
      </div>
    </div>

    <AppRightPanel v-model:rightMenuClick="rightMenuClick"
                   :rightMenuActive="rightMenuActive"
                   @right-menu-click="onRightMenuClick"/>


    <!-- modals globali -->
    <MailModal/>
    <ChatModal/>

    <div class="layout-mask modal-in"></div>
  </div>
</template>

<script setup>
import {onMounted, onUnmounted, ref, computed, watch, getCurrentInstance} from "vue";
import {DomHandler} from 'primevue/utils';
import AppTopBar from "./AppTopbar";
import AppFooter from "./AppFooter";
import EventService from "@/services/EventService";
import AppRightPanel from "@/AppRightPanel";
import MailModal from "@/components/common/MailModal";
import ChatModal from "@/components/chat/ChatModal";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import {TOAST} from "@/components/common/constants/Events";
import useBrowser from "@/composable/useBrowser";

defineProps({
  topbarTheme: String,
  menuTheme: String
})

defineEmits(["change-color-scheme", "change-component-theme", "topbar-theme", "menu-theme"])

const toast = useToast();
const route = useRoute();
const {isMobile} = useBrowser();

const menuActive = ref(false);
const layoutMode = ref("sidebar");
const colorScheme = ref("light");
const sidebarActive = ref(true);
const sidebarStatic = ref(true);
// const staticMenuDesktopInactive = ref(false);
const staticMenuMobileActive = ref(false);
const menuClick = ref(false);
const searchActive = ref(false);
const searchClick = ref(false);
const topbarItemClick = ref(false);
const activeTopbarItem = ref(null);
const userMenuClick = ref(false);
const topbarUserMenuActive = ref(false);
const topbarUserMenuClick = ref(false);
const rightMenuClick = ref(false);
const rightMenuActive = ref(false);
const configActive = ref(false);
const configClick = ref(false);

const containerClass = computed(() => {
  return [
    "layout-wrapper",
    {
      "layout-sidebar": layoutMode.value === "sidebar",
      "layout-static": layoutMode.value === "sidebar" && sidebarStatic.value,
      "layout-horizontal": layoutMode.value === "horizontal",
      "layout-slim": layoutMode.value === "slim",
      "layout-rightpanel-active": rightMenuActive.value,
      'layout-mobile-active': staticMenuMobileActive.value,
      "p-input-filled": getCurrentInstance().appContext.config.globalProperties.$appState.inputStyle === "filled",
      "p-ripple-disabled": !getCurrentInstance().appContext.config.globalProperties.$primevue.config.ripple,
    },
    "layout-menu-light" + ' layout-topbar-light'
  ];
});


watch(
    () => route, () => {
      menuActive.value = false;
      toast.removeAllGroups()
    }
)

onMounted(() => {
  EventService.on(TOAST, (msg) => {
    toast.add(msg);
  });

  EventService.on("notifications", onRightMenuButtonClick);

})

onUnmounted(() => {
  EventService.off(TOAST);
  EventService.off("notifications");
})


const onDocumentClick = () => {
  if (!searchClick.value && searchActive.value) {
    onTopbarSearchHide();
  }

  if (!topbarUserMenuClick.value && topbarUserMenuActive.value) {
    topbarUserMenuActive.value = false;
    topbarUserMenuClick.value = false;
  }

  if (!rightMenuClick.value) {
    rightMenuActive.value = false;
  }

  if (!menuClick.value) {
    if (isSlim() || isHorizontal()) {
      EventService.emit('reset-active-index');
      menuActive.value = false;
    }

    if (staticMenuMobileActive.value) {
      hideOverlayMenu();
    }

    unblockBodyScroll();
  }

  if (configActive.value && !configClick.value) {
    configActive.value = false;
  }

  searchClick.value = false;
  configClick.value = false;
  userMenuClick.value = false;
  rightMenuClick.value = false;
  topbarUserMenuClick.value = false;
  menuClick.value = false;
}

const onToggleMenu = (event) => {
  menuClick.value = true;
  sidebarStatic.value = !sidebarStatic.value;
  event.preventDefault();
}

const onMenuClick = (event) => {
  if (isHorizontal() && DomHandler.hasClass(event.target, 'layout-menu-container')) {
    EventService.emit('reset-active-index');
    menuClick.value = false;
    menuActive.value = false;
  }
  menuClick.value = true;
}


const onMenuButtonClick = (event) => {
  menuClick.value = true;
  topbarUserMenuActive.value = false;
  rightMenuActive.value = false;

  if (isMobile()) {
    staticMenuMobileActive.value = !staticMenuMobileActive.value;
    if (staticMenuMobileActive.value) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }

  event.preventDefault();
}

const onMenuItemClick = (event) => {
  if (!event.item.items) {
    EventService.emit('reset-active-index');
    hideOverlayMenu();
  }
  if (!event.item.items && isSlim()) {
    menuActive.value = false;
  }
}

const onRootMenuItemClick = () => {
  menuActive.value = !menuActive.value;
}

const onRightMenuClick = () => {
  rightMenuClick.value = true;
}

const onRightMenuButtonClick = (event) => {
  rightMenuClick.value = true;
  rightMenuActive.value = !rightMenuActive.value;
  hideOverlayMenu();

  if(null != event) {
    event.preventDefault();
  }
}

const hideOverlayMenu = () => {
  staticMenuMobileActive.value = false;
  unblockBodyScroll();
}

const onSidebarMouseOver = () => {
  sidebarActive.value = !isMobile();
}

const onSidebarMouseLeave = () => {
  sidebarActive.value = false;
}

const onTopbarSearchToggle = () => {
  searchActive.value = !searchActive.value;
  searchClick.value = true;
}

const OnTopbarSearchClick = () => {
  searchClick.value = true;
}

const onTopbarSearchHide = () => {
  searchActive.value = false;
  searchClick.value = false;
}

const onTopbarUserMenuClick = () => {
  topbarUserMenuActive.value = !topbarUserMenuActive.value;
  topbarUserMenuClick.value = true;
}

const blockBodyScroll = () => {
  if (document.body.classList) {
    document.body.classList.add('blocked-scroll');
  } else {
    document.body.className += ' blocked-scroll';
  }
}

const unblockBodyScroll = () => {
  if (document.body.classList) {
    document.body.classList.remove('blocked-scroll');
  } else {
    document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
        'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }
}

const isSlim = () => {
  return layoutMode.value === "slim";
}

const isHorizontal = () => {
  return layoutMode.value === "horizontal";
}


// const isDesktop = () => {
//   return window.innerWidth > 991;
// }

// const onConfigClick = () => {
//   configClick.value = true;
// }

// const onConfigButtonClick = () => {
//   configActive.value = !configActive.value;
//   configClick.value = true;
// }

// const onChangeLayoutMode = (mode) => {
//   layoutMode.value = mode;
//   if (mode === 'sidebar') {
//     if (sidebarStatic.value) {
//       sidebarActive.value = true;
//     }
//   } else {
//     sidebarActive.value = false;
//
//     if (topbarTheme.value !== menuTheme.value) {
//       emit('menu-theme', topbarTheme.value);
//     }
//   }
// }

// const onTopbarThemeChange = (theme) => {
//   emit('topbar-theme', theme);
// }

// const onMenuThemeChange = (theme) => {
//   emit('menu-theme', theme);
// }

// const changeComponentTheme = (theme) => {
//   emit('change-component-theme', theme);
// }

// const changeColorScheme = (scheme) => {
//   colorScheme.value = scheme;
//   emit('change-color-scheme', scheme);
//   const appState =  getCurrentInstance().appContext.config.globalProperties.appState;
//   appState.colorScheme = scheme;
// }


</script>

<style lang="scss">
</style>
