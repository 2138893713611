<template>
    <span @click="showChatModal" class="chat mr-3">

       <!-- nessun messaggio -->
      <i class="pi pi-comment messaggio"
         v-tooltip.bottom="t('label.chat')"
         v-if="unreadMessagesNum === 0"/>

      <!-- nuovi messaggi -->
       <i class="pi pi-comment messaggio"
          v-tooltip.bottom="t('label.chat')"
          v-badge.danger="unreadMessagesNum"
          v-if="unreadMessagesNum > 0"/>

  </span>
</template>

<script setup>
import EventService from "@/services/EventService";
import {SHOW_CHAT} from "@/components/common/constants/Events";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {computed} from "vue";

const {t} = useI18n();
const store = useStore();

const unreadMessagesNum = computed(() => store.getters["chat/unreadMessagesNum"]);

const showChatModal = () => {
  EventService.emit(SHOW_CHAT, {});
}

/*const addTestMessage = () => {
  const messaggi = [{
    username: "romrail@test.com",
    messaggio: {
      recipient: "romrail@test.com",
      sender: "acos@test.com",
      content: "Messaggio di  test",
      dateTime: new Date()
    }
  }, {
    username: "romrail@test.com",
    messaggio: {
      recipient: "romrail@test.com",
      sender: "exman@test.com",
      content: "Messaggio di  test",
      dateTime: new Date()
    }
  }];

  for (const messaggio of messaggi) {
    store.dispatch("chat/addMessaggio", messaggio);
  }
}*/
</script>

<style scoped>
.chat {
  cursor: pointer;
  color: #78ad78;
  opacity: 0.8;
}

.messaggio {
  font-size: 2rem
}

</style>
