import {useToast} from "primevue/usetoast";
import {useI18n} from "vue-i18n";


export default function () {
    const toast = useToast();
    const {t} = useI18n();

    const SEVERITY_SUCCESS = 'success';
    const SEVERITY_ERROR = 'error';
    const SEVERITY_WARNING = 'warning';
    const SEVERITY_INFO = 'info';
    const MESSAGE_LIFE = 6000;

    const successWithKey = (key) => {
        const message = t(key);
        success(message);
    }

    const success = (message) => {
        toast.add({
            severity: SEVERITY_SUCCESS,
            summary: t('label.success'),
            detail: message,
            life: MESSAGE_LIFE
        });
    }

    const warning = (message) => {
        toast.add({
            severity: SEVERITY_WARNING,
            detail: message,
            life: MESSAGE_LIFE
        });
    }

    const info = (message, title = "", sticky = false) => {
        let params = {
            severity: SEVERITY_INFO,
            summary: title,
            detail: message
        };

        if (!sticky) {
            params.life = MESSAGE_LIFE;
        }

        toast.add(params);

    }

    const error = (message) => {
        toast.add({
            severity: SEVERITY_ERROR,
            summary: t('label.error'),
            detail: message,
            life: MESSAGE_LIFE
        });
    }

    return {successWithKey, success, info, warning, error}
}
