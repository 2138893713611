import httpClient from "@/services/HttpService";
import useRestApi from "@/composable/useRestService";

const {fileDownload, fileUpload} = useRestApi();

export default {
    items: () => {
        return httpClient.get('/viaggio/all');
    },
    watchingViaggi: async () => {
        return (await httpClient.get('/viaggio/watching')).data;
    },
    findByCodice: async (codice) => {
        return (await httpClient.get(`/viaggio/codice/${codice}`)).data;
    },
    findDaPianificare: async () => {
        return (await httpClient.get(`/viaggio/da-pianificare`));
    },
    findInCorso: async () => {
        return (await httpClient.get(`/viaggio/in-corso`));
    },
    findEseguiti: async () => {
        return (await httpClient.get(`/viaggio/eseguiti`));
    },
    update: (item) => {
        return httpClient.post('/viaggio/update', item);
    },
    insert: (item) => {
        return httpClient.post('/viaggio/insert', item)
    },
    updateDateCarico: (viaggio, params) => {
        return httpClient.put(`/viaggio/${viaggio.codice}/date-carico`, params);
    },
    updateDateScarico: (viaggio, params) => {
        return httpClient.put(`/viaggio/${viaggio.codice}/date-scarico`, params);
    },
    delete: (item) => {
        return httpClient.post('/viaggio/delete', item);
    },
    downloadCim: (viaggio) => {
        fileDownload(`/viaggio/${viaggio.id}/export/cim`);
    },
    downloadCuv: (viaggio) => {
        fileDownload(`/viaggio/${viaggio.id}/export/cuv`);
    },
    downloadCit23Vuoto: (viaggio) => {
        fileDownload(`/viaggio/${viaggio.id}/export/cit23/vuoto`);
    },
    downloadCit23Pieno: (viaggio) => {
        fileDownload(`/viaggio/${viaggio.id}/export/cit23/pieno`);
    },
    uploadFile: async (viaggio, documentType, data) => {
        await fileUpload(`/viaggio/${viaggio.id}/files/upload/single/${documentType}`, data);
    },
    uploadFiles: async (viaggio, files) => {
        await fileUpload(`/viaggio/${viaggio.id}/files/upload/multiple`, files);
    },
    uploadedFiles: async (viaggio) => {
        return (await httpClient.get(`/viaggio/${viaggio.id}/files/list/all`)).data;
    },
    deleteFile: async (viaggio, fileName) => {
        await httpClient.post(`/viaggio/${viaggio.id}/files/delete/single/` + fileName);
    },
    start: async (viaggio) => {
        await httpClient.get(`/viaggio/${viaggio.codice}/start`);
    },
    isStarted: async (viaggio) => {
        return (await httpClient.get(`/viaggio/${viaggio.codice}/started`)).data;
    },
    isStartable: async (viaggio) => {
        return (await httpClient.get(`/viaggio/${viaggio.codice}/startable`)).data;
    },
    isPianificazioneChiusa: async (viaggio) => {
        return (await httpClient.get(`/viaggio/${viaggio.codice}/pianificazione/chiusa`)).data;
    },
    chiudiViaggio: async (viaggio) => {
        return (await httpClient.get(`/viaggio/${viaggio.codice}/chiudi`)).data;
    },
    chiudiCheck: async (viaggio) => {
        return (await httpClient.get(`/viaggio/${viaggio.id}/chiudi/check`)).data;
    },
    isViaggioClosed: async (viaggio) => {
        return (await httpClient.get(`/viaggio/${viaggio.codice}/chiuso`)).data;
    },
    isViaggioCloseable: async (viaggio) => {
        return (await httpClient.get(`/viaggio/${viaggio.codice}/closeable`)).data
    },
    acl: async (viaggio, mode) => {
        return (await httpClient.get(`/viaggio/acl/${viaggio.codice}/${mode}`)).data
    },
    selectImpresaFerroviaria: (viaggio, impresaFerroviaria) => {
        return httpClient.get(`/viaggio/${viaggio.codice}/select/railway_company/${impresaFerroviaria.id}`);
    }
}
