import httpClient from "@/services/HttpService";
import useRestApi from "@/composable/useRestService";

const {fileDownload, fileUpload} = useRestApi();

export default {

    items: () => {
        return httpClient.get('/contratto/all');
    },
    drafts: () => {
        return httpClient.get('/contratto/all/bozze');
    },
    findById: (id) => {
        return httpClient.get('/contratto/id/' + id);
    },
    update: (item) => {
        return httpClient.post('/contratto/update', item);
    },
    updateCodiceSeller: (item) => {
        return httpClient.post('/contratto/update/codice-seller', item);
    },
    updateCodiceBuyer: (item) => {
        return httpClient.post('/contratto/update/codice-buyer', item);
    },
    updateCodiceContrattoForwarding: (item) => {
        return httpClient.post('/contratto/update/codice-contratto-forwarding', item);
    },
    insert: (item) => {
        return httpClient.post('/contratto/insert', item);
    },
    saveDraft: async (item) => {
        return (await httpClient.post('/contratto/salva/bozza', item)).data;
    },
    confirm: async (contratto) => {
        return httpClient.get(`/contratto/confirm/${contratto.id}`);
    },
    isConfirmable: async (contratto) => {
        return (await httpClient.get(`/contratto/confirmable/${contratto.id}`)).data;
    },
    isExecutionManagerSelectable: async (contratto) => {
        return (await httpClient.get(`/contratto/executionman/selectable/${contratto.id}`)).data;
    },
    areCompaniesSelectable: async (contratto) => {
        return (await httpClient.get(`/contratto/companies/selectable/${contratto.id}`)).data;
    },
    uploadFiles: async (contratto, documentType, data) => {
        await fileUpload(`/contratto/${contratto.id}/files/upload/single/${documentType}`, data);
    },
    deleteFile: (contratto, fileName) => {
        return httpClient.post(`/contratto/${contratto.id}/files/delete/single/` + fileName);
    },

    uploadedFiles: (contratto) => {
        return httpClient.get(`/contratto/${contratto.id}/files/list/all`);
    },
    uploadContrattoForwarding: (contratto, files) => {
        const formData = new FormData();
        for (let i = 0; i < files.files.length; i++) {
            formData.append("files", files.files[i]);
        }
        return httpClient.post(`/contratto/${contratto.id}/forwarding/files/upload`, formData);
    },
    exportPdf: (contratto) => {
        fileDownload(`/contratto/${contratto.id}/export/pdf`);
    },
    changes: (contratto) => {
        return httpClient.get(`/contratto/${contratto.id}/changes`);
    },
    compareVersions: (data) => {
        return httpClient.get('/contratto/' + data.id + '/compare/source/' + data.source + '/destination/' + data.dest);
    },
    searchNhms: (term) => {
        return httpClient.get(`/contratto/search/nhms/` + term);
    },
    loadCurrencies: () => {
        return httpClient.get('/contratto/currencies');
    },
    searchLocodes: (query) => {
        return httpClient.get(`/locode/${query}`);
    },
    searchIncoterms: (query) => {
        return httpClient.get('/contratto/incoterms/search/' + query)
    },
    computeQuantityPerTrain: async (goodQuantity, trainAmount) => {
        return (await (httpClient.get(`/contratto/compute/quantity/${goodQuantity}/train/${trainAmount}`))).data;
    },
    selectExecutionManager: (params) => {
        return httpClient.post("/contratto/select/executionmanager", params);
    },
    selectSoggetti: (contrattoId, body) => {
        return httpClient.post(`/contratto/${contrattoId}/select/soggetti`, body);
    },
    selectSecondSurveyor: (contratto, surveyor) => {
        return httpClient.get(`/contratto/${contratto.id}/select/second/surveyor/${surveyor.id}`);
    },
    selectSecondCustomBroker: (contratto, customBroker) => {
        return httpClient.get(`/contratto/${contratto.id}/select/second/custom_broker/${customBroker.id}`);
    }

}
