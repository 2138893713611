import restService from "@/services/NodoRestService";

export default {
    namespaced: true,
    state() {
        return {
            items: [],
            tipinodo: []
        }
    },
    getters: {
        items: (state) => state.items,
        tipinodo: (state) => state.tipinodo
    },
    actions: {
        loadAll: async ({commit}) => {
            const items = (await restService.items()).data;
            commit("saveItems", items);
        },
        update: async ({dispatch}, item) => {
            await restService.update(item);
            dispatch('loadAll');
        },
        insert: async ({dispatch}, item) => {
            await restService.insert(item);
            dispatch('loadAll');
        },
        delete: async ({dispatch}, item) => {
            await restService.delete(item);
            dispatch('loadAll');
        },
        loadAllTipiNodo: async ({commit}) => {
            const tipinodo = (await restService.tipiNodo()).data;
            commit('saveTipiNodo', tipinodo)
        },
        search: async ({commit}, query) => {
            const nodi = (await restService.searchNodi(query)).data;
            await commit('saveItems', nodi);
        },
    },
    mutations: {
        saveItems(state, items) {
            state.items = items;
        },
        saveTipiNodo(state, tipinodo) {
            state.tipinodo = tipinodo;
        }
    }
}