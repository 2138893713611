import httpClient from "@/services/HttpService";
import useRestApi from "@/composable/useRestService";

const {fileUpload} = useRestApi();

export default {
    findAll: async () => {
        return (await httpClient.get('/carro/')).data;
    },
    findAllAttivi: async () => {
        return (await httpClient.get('/carro/attivo')).data;
    },
    findPosizioniGPSCarro: async (idCarro) => {
        return (await httpClient.get(`/equipment/posizionamento/gps/carro/${idCarro}`)).data;
    },
    search: async (term) => {
        return (await httpClient.get('/carro/search/' + term)).data;
    },
    insert: async (carro) => {
        return (await httpClient.post('/carro/', carro)).data;
    },
    insertAll: async (carri) => {
        return (await httpClient.post('/carro/batch', carri)).data;
    },
    update: async (carro) => {
        return (await httpClient.put('/carro/', carro)).data;
    },
    delete: async (carro) => {
        return (await httpClient.delete('/carro/', {data: carro})).data
    },
    uploadedFiles: async (carro) => {
        return (await httpClient.get(`/carro/${carro.id}/files/list/all`)).data;
    },
    uploadFiles: async (carro, documentType, data) => {
        await fileUpload(`/carro/${carro.id}/files/upload/single/${documentType}`, data);
    },
    deleteFile: async (carro, fileName) => {
        return (await (httpClient.post(`/carro/${carro.id}/files/delete/single/` + fileName))).data;
    },
    findScadenzeCarriFromToday: async (daysOffset) => {
        return (await (httpClient.get(`/carro/scadenze/${daysOffset}`))).data;
    },
    findScadenzeCarriByRange:  async (startDate, endDate) => {
        return (await httpClient.get(`/carro/scadenze/range/${startDate}/${endDate}`)).data;
    },
    findScadenzeCarro: async (carro) => {
        return (await (httpClient.get(`/carro/${carro.id}/scadenze`))).data;
    },
    saveScadenzeCarro: async (carro, scadenze) => {
        return (await (httpClient.post(`/carro/${carro.id}/scadenze`, scadenze))).data;
    },
    findMerciCarro: async (carro, limit) => {
        return (await (httpClient.get(`/carro/${carro.matricola}/merci/${limit}`))).data;
    },
    findStatiCarro: async (carro) => {
        return (await (httpClient.get(`/carro/${carro.id}/stati`))).data;
    },
    stati: async () => {
        return (await (httpClient.get('/carro/stati/all'))).data;
    },
    saveStatus: async (carro, status) => {
        return (await (httpClient.post(`/carro/${carro.id}/stato/save`, status))).data;
    },
    downloadTemplate: async () => {
        const response = await httpClient.get(`/carro/1/files/template/carro/download`,{responseType: 'blob'});

        // grab file name from headers
        const headers = response.headers;
        const contentDisposition = headers['content-disposition'];
        const fileName = contentDisposition.substring(contentDisposition.indexOf("=") + 1);

        // download with fake link click
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }
}


