import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/profilo',
        name: 'Profile',
        component: () => import('@/components/auth/ProfiloUtente')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/components/dashboard/Dashboard')
    },
    {
        path: '/dashboard/admin',
        name: 'Admin dashboard',
        component: () => import('@/components/dashboard/AdminDashboard')
    },
    {
        path: '/contratti',
        name: 'Contratti',
        component: () => import('@/components/contratto/ContrattoTable')
    },
    {
        path: '/contratto/wizard',
        component: () => import('@/components/contratto/wizard/ContrattoWizard.vue'),
        children: [
            {
                path: '/contratto/wizard/contratto',
                component: () => import('@/components/contratto/wizard/ContrattoStep.vue')
            },
            {
                path: '/contratto/wizard/soggetto',
                component: () => import('@/components/contratto/wizard/SoggettiStep.vue')
            },
            {
                path: '/contratto/wizard/upload',
                component: () => import('@/components/contratto/wizard/UploadStep.vue')
            }
        ],
    },
    {
        path: '/contratti-file',
        name: 'UploadFile',
        component: () => import('@/components/contratto/ContrattoUploadFileDialog')
    },
    {
        path: '/soggetti',
        name: 'Soggetti',
        component: () => import('@/components/anagrafica/soggetto/SoggettoTable')
    },
    {
        path: '/soggetti/richieste',
        name: 'Soggetti richieste',
        component: () => import('@/components/anagrafica/soggetto/richieste/SoggettoRichiesteTable')
    },
    {
        path: '/nodi',
        name: 'Nodi',
        component: () => import('@/components/anagrafica/nodo/NodoTable')
    },
    {
        path: '/tipi-documenti',
        name: 'Tipi documenti',
        component: () => import('@/components/anagrafica/tipoDocumento/TipoDocumentoTable')
    },
    {
        path: '/carri',
        name: 'Carri',
        component: () => import('@/components/anagrafica/carro/CarroTable')
    },
    {
        path: '/mute',
        name: 'Mute',
        component: () => import('@/components/anagrafica/muta/MutaTable')
    },
    {
        path: '/utenti',
        name: 'Utenti',
        component: () => import('@/components/anagrafica/utente/UtenteTable')
    },
    {
        path: '/utente',
        name: 'Utente',
        component: () => import('@/components/auth/ProfiloUtente')
    },
    {
        path: '/viaggio',
        name: 'Viaggio',
        component: () => import('@/components/viaggio/ViaggiTabView')
    },
    {
        path: '/email_template',
        name: 'Email template',
        component: () => import('@/components/anagrafica/email/ModelloEmail')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('@/pages/NotFound')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        redirect: '/notfound'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(function (to, from, next) {
    window.scrollTo(0, 0);
    next();
});

export default router
