import httpClient from "@/services/HttpService";

export default {

    items: () => {
        return httpClient.get('/nodo/all');
    },
    findById: async (nodoId) => {
        return (await httpClient.get(`/nodo/id/${nodoId}`)).data;
    },
    update: (item) => {
        return httpClient.post('/nodo/update', item);
    },
    insert: (item) => {
        return httpClient.post('/nodo/insert', item)
    },
    delete: (item) => {
        return httpClient.post('/nodo/delete', item);
    },
    tipiNodo: () => {
        return httpClient.get('/nodo/tipi/all');
    },
    searchNodi: (query) => {
        return httpClient.get('/nodo/search/' + query);
    }

}
