const ADMIN = "admin";
const BROKER = "broker";
const BUYER = "buyer";
const SELLER = "seller";
const EXECUTION_MANAGER = "execution_manager";
const SPEDIZIONIERE = "spedizioniere";
const CARICATORE = "caricatore";
const SCARICATORE = "scaricatore";
const SURVEYOR = "surveyor";
const CUSTOM_BROKER = "custom_broker"
const IMPRESA_FERROVIARIA = "railway_company";

// TODO: lettura dati da server (duplicato con UserRole lato server)
export default {
    ADMIN, BROKER, BUYER, SELLER, EXECUTION_MANAGER, SPEDIZIONIERE, CARICATORE, SCARICATORE, SURVEYOR, CUSTOM_BROKER,IMPRESA_FERROVIARIA
}
