import restService from "@/services/CarroRestService";

const getDefaultState = () => {
    return {
        carri: [],
        carriAttivi: [],
    }
}

const state = getDefaultState();

const getters = {
    carri: (state) => state.carri,
    carriAttivi: (state) => state.carriAttivi,
}

const actions = {
    loadAll: async ({commit}) => {
        const carri = await restService.findAll();
        commit('saveCarri', carri);
    },
    loadAllAttivi: async ({commit}) => {
        const carriAttivi = await restService.findAllAttivi();
        commit('saveCarriAttivi', carriAttivi);
    },
    insert: async ({dispatch}, data) => {
        const carroCreated = await restService.insert(data.carro);
        await restService.saveScadenzeCarro(carroCreated, data.scadenze);
        dispatch('loadAll');
    },
    insertAll: async ({dispatch}, carri) => {
        await restService.insertAll(carri);
        dispatch('loadAll');
    },
    update: async ({dispatch}, data) => {
        await restService.update(data.carro);
        await restService.saveScadenzeCarro(data.carro, data.scadenze);
        dispatch('loadAll');
    },
    delete: async ({dispatch}, carro) => {
        await restService.delete(carro);
        dispatch('loadAll');
    }
}

const mutations = {
    saveCarri(state, carri) {
        state.carri = carri;
    },
    saveCarriAttivi(state, carriAttivi) {
        state.carriAttivi = carriAttivi;
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
