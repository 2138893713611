<template>
  <div class="menu-wrapper" :class="{ 'layout-sidebar-active' : sidebarActive}"
       @click="onMenuClick" @mouseover="onSidebarMouseOver" @mouseleave="onSidebarMouseLeave">
    <div class="sidebar-logo">
      <router-link to="/dashboard">
        <img loading="lazy" :class="{'logo-size-full' : sidebarActive}" src="@/assets/img/logo-full.svg" v-if="sidebarActive">
        <img loading="lazy" :class="{'logo-size-small' : !sidebarActive}" src="@/assets/img/logo-small.svg" v-if="!sidebarActive" />
      </router-link>
      <a class="sidebar-pin" @click="onToggleMenu($event)">
        <span class="pin"></span>
      </a>
    </div>

    <div class="layout-menu-container">
      <AppSubmenu class="layout-menu" :items="menu" :layoutMode="layoutMode" :parentMenuItemActive="true"
                  :menuActive="menuActive" :mobileMenuActive="mobileMenuActive" :root="true"
                  @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"/>
    </div>
  </div>
</template>

<script setup>
import AppSubmenu from "./AppSubmenu";
import {computed} from "vue";
import {useStore} from "vuex";

const emit = defineEmits(["menu-click", "menuitem-click", "root-menuitem-click", "toggle-menu", "sidebar-mouse-over", "sidebar-mouse-leave"])
const props = defineProps({
  sidebarActive: Boolean,
  sidebarStatic: Boolean,
  layoutMode: String,
  menuActive: Boolean,
  mobileMenuActive: Boolean
})

const store = useStore();

const menu = computed(() => {
  return store.getters['auth/loggedUser'].menu;
})

const onSidebarMouseOver = () => {
  if ("sidebar" === props.layoutMode && !props.sidebarStatic) {
    emit("sidebar-mouse-over");
  }
}

const onSidebarMouseLeave = () => {
  if ("sidebar" === props.layoutMode && !props.sidebarStatic) {
    setTimeout(() => {
      emit("sidebar-mouse-leave");
    }, 150);
  }
}

const onToggleMenu = (event) => {
  emit("toggle-menu", event);
}

const onMenuClick = (event) => {
  emit("menu-click", event);
}

const onMenuItemClick = (event) => {
  emit("menuitem-click", event);
}

const onRootMenuItemClick = (event) => {
  emit("root-menuitem-click", event);
}

</script>

