<template>
   <span @click="logout" class="logout-container">
    <i class="pi pi-sign-out"
       v-tooltip.bottom="t('action.logout', 2)"
       style="font-size: 1.8rem; color: darkgrey"/>
  </span>
</template>

<script setup>
import {getCurrentInstance} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

const {t} = useI18n();
const store = useStore();
const $keycloak = getCurrentInstance().appContext.config.globalProperties.$keycloak;

async function logout() {
  await store.dispatch('notifiche/reset');
  $keycloak.logout();
}
</script>

<style scoped>
.logout-container{
  cursor: pointer;
  opacity: 0.8;
}
</style>
