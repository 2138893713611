const getDefaultState = () => {
    return {
        contratto: {},
        buyer: {},
        seller: {}
    }
}

const state = getDefaultState();

const getters = {
    contratto: (state) => state.contratto,
    buyer: (state) => state.buyer,
    seller: (state) => state.seller
}

const actions = {
    setContratto: async ({commit}, contratto) => {
        commit('saveContratto', contratto);
    },
    setBuyer: async ({commit}, buyer) => {
        commit('saveBuyer', buyer);
    },
    setSeller: async ({commit}, seller) => {
        commit('saveSeller', seller);
    },
    reset: async ({commit}) => {
        commit('resetState');
    }
}

const mutations = {
    saveContratto(state, contratto) {
        state.contratto = contratto;
    },
    saveBuyer(state, buyer) {
        state.buyer = buyer;
    },
    saveSeller(state, seller) {
        state.seller = seller;
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
